import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const VolumizerHairAdditionsWomen = () => {

  const seoData = {
    title: 'Volumizer Hair Additions for Women in Delhi - Radiance  ',
    description: 'To know more about volumizer hair additions for women in Delhi go through our guide article. This will lead you to volumizer hair adding solutions with Radiance',
    keywords: ['Volumizer Hair Additions for Women in Delhi'],
  };
  
  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
        img: "/volumizer-hair-additions-for-women.png",
        content: (
            <div>
                <h2>Volumizer Hair Additions</h2>
                <p>How many times have you dreamt of having those VOLUMINOUS LOCKS but ended up with mere shafts? Your lifestyle and eating habits restricted you from having the same. Ok! But we are not here to start a blame game but with an amazing solution to make that MANE look super voluminous. Volumizing products? No! This time you guessed it WRONG! RADIANCE has come up with even better HAIR SAVIOUR! THE HAIR ADDITIONS.</p>
                <p>Volumizing products? No! This time you guessed it WRONG! RADIANCE has come up with even better HAIR SAVIOUR! THE HAIR ADDITIONS.</p>
                <p>Hairfall has been a brewing issue faced by women all over the world. The concern arises when new hair follicles fail to grow on the same spot. The insufficient hair growth is what we name THINNING! HAIR VOLUMIZERS are the best that one can have to fight the situation! At <a href="/" style={{ color: "#373737"}}>Radiance Hair Studio</a> we work with 100% natural human hair volumizer that adds an extra volume to the LIMP LOCKS! We encountered women with the flat, straight falling mane that lacked CHARACTER too! Our hair flaunts the gorgeous character we already are. Thinning hair or the scanty hair shafts on our scalp should never be a reason for the FALL IN CONFIDENCE. At RADIANCE we try to fix the same clipping the human hair volumizer to the existing hair roots. Not every solution involves SURGERY! The updated beauty industry has a lot more to your surprise. HAIR VOLUMIZERS are something you would love to adopt!</p>

            </div>
        )
    }
];

const sectionS3 = [
    {
        smallImg: "/volumizer-hair-additions-for-women1.png",
        largeImg: "/volumizer-hair-additions-for-women2.png",
        content: (
            <div>
                <h2>Benefits: The Beauty!</h2>
                <p>Investing in a good human hair volumizer to flaunt the beautiful hair can actually burn your pocket to ashes. There should be reasons enough to make the most out of the investment you indulge in. Here are some of the beauty benefits that will convince you to adopt the same. After all, WE ARE WORTH IT!</p>
                <ul>
                    <li>
                        The hair additions are tied manually by our <a href="/contact.php" style={{ color: "#373737"}}>experts</a> to the roots of existing mane, thus the additions manage to blend with your mane and add the extra volume boosting your confidence.
                    </li>
                    <li>Human hair volumizer feels like one's natural hair and thus can be styled desirably. Styling gets easy that was otherwise difficult with the thin old hair.</li>
                    <li>The hair volumizer is like an overnight transformation that enhances the texture, color, volume and length of our mane reflecting our character inside out. BORN STRONG!</li>
                    <li>The LIMP LOCKS are at bay! The thick luscious beautiful mane is what you own with the 100% natural human hair. YOU GO, GIRL!</li>
                </ul>
                <p>Confidence is all you need to make a mark of your own! All the confidence that was gone with the falling hair gets back to shape with Radiance Hair Studio. At RADIANCE we customize the best of human hair volumizer for individuals considering the best for them. QUEENS should always own a PRICELESS CROWN!</p>
            </div>
        )
    }
];

const sectionS4 = [
    {
        title: "FAQs: Your LOCKS UNLOCKED!",
        description: "We hear your concern at RADIANCE. We understand the queries that need to be answered before you step forward to get your additional hair volumizer. RADIANCE has got your back and here you go with some answers to your questions that were bothering you!",
        description2: "We hope that we have cleared all your queries by now. Every one of us deserves the best for our mane. Experts at RADIANCE offer you a free consultation and answer all your questions and suggest the best for your hair.",
        faq: [
            {
                title: "Why should I go for additional hair volumizer instead of volumizing products?",
                description: "Volumizing products or additional volumizer are personal choices of course. But with additional volumizer, one gets a long term better solution for hair thinning."
            },
            {
                title: "What is the difference between a hair extension and hair volumizer?",
                description: "The hair extensions usually add length to your already existing hair. The job of hair volumizer is to add the extra hair to the scalp visible."
            },
            {
                title: "How long the additional volumizer lasts for?",
                description: "The additional human hair volumizer can last you for all most a year with proper use and care."
            },
            {
                title: "Does the volumizer cause any negative effect on already existing mane?",
                description: "Additional hair volumizer is usually hand tied to the roots of the existing hair. Thus the indulgence of any chemicals or products is removed. No harm is caused to your scalp or hair."
            },
            {
                title: "Can I style my hair after adapting hair volumizer?",
                description: "One can indulge in any kind of styling, hair coloring, curling or straightening after hair additional hair volumizer. But of course, excessive use of anything can cause damage."
            }
        ],
    }
];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <TitleBar2 title="Volumizer Hair Additions Women's" banner="/volumizer-hair-additions.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Volumizer Hair Additions" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default VolumizerHairAdditionsWomen;
